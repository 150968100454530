import React from "react";
import { useLocation } from "react-router-dom";

import { Layout, theme } from "@fleet.co/tarmac";

import {
  faBarcode, faEnvelope, faFolder, faFolderOpen, faHandHoldingBox, faHandHoldingUsd, faHandshake, faRecycle, faSparkles, faTriangleExclamation, faTruck,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faCoins,
} from "@fortawesome/pro-solid-svg-icons";
import SideIcon from "../blocks/SideIcon";

const SideNav = () => {
  const location = useLocation();
  const folderIcon = location.pathname === "/files" ? faFolderOpen : faFolder;

  return (
    <Layout
      direction="column"
      fullHeight
      sx={{
        paddingTop: 1, width: 64, zIndex: "1", backgroundColor: theme.palette.background.secondary, height: "100vh", position: "fixed",
      }}
    >
      <SideIcon icon={faHandHoldingUsd} link="/leasers" altLink="/" page="Leasers" />
      <SideIcon icon={faEnvelope} link="/envelopes" page="Funding envelopes" />
      <SideIcon icon={folderIcon} link="/files" page="File validation" />
      <SideIcon icon={faTruck} link="/logistics" page="Logistics" />
      <SideIcon icon={faHandHoldingBox} link="/deliveries" page="Deliveries" />
      <SideIcon icon={faRecycle} link="/renewal" page="Renewal" />
      <SideIcon icon={faCoins} link="/contracts" page="Contracts" />
      <SideIcon icon={faBarcode} link="/products" page="Fleet catalog" />
      <SideIcon icon={faHandshake} link="/punctual-offers" page="Punctual offers" />
      <SideIcon icon={faTriangleExclamation} link="/unpaid" page="Unpaid" />
      <SideIcon icon={faSparkles} link="/ai-it-support" page="AI IT Support" />
    </Layout>
  );
};

export default SideNav;
