import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert, Button, Dialog, Icon, IconButton, Link, ProgressBar,
} from "@fleet.co/tarmac";
import {
  faCaretDown, faCaretUp, faCheckCircle, faCircle, faDownload,
} from "@fortawesome/pro-regular-svg-icons";
import UserContext from "../../../tools/UserContext";

import FormatDate from "../../../tools/FormatDate";
import CopyButton from "../../../tools/CopyButton";
import FileTypeNiceName from "../../blocks/FileTypeNiceName";
import GenerateFiles from "../../blocks/GenerateFiles";
import ActionConfirmation from "../../common/ActionConfirmation";

import styles from "./ContractSigningRow.module.scss";
import { downloadFileBlob } from "../../../helpers/FilesHelpers";
import { formatMoney } from "../../../helpers/formatHelpers";
import { getLeaserLabel } from "../../../tools/OrderTools";

const ContractSigningRow = (props) => {
  const history = useHistory();
  const { user: adminUser } = useContext(UserContext);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);
  const [error, setError] = useState(null);
  const [sendLeaserPackage, setSendLeaserPackage] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const handleMouse = () => setIsHovering(!isHovering);
  const [isKpiWarningOpen, setIsKpiWarningOpen] = useState(false);
  const [orderStatus, setOrderStatus] = useState(props.invoice.status);

  const saveStatusChange = async () => {
    // Only send to backend if :
    await adminUser.api.modifyOrder(props.invoice.id, { status: orderStatus });
    props.reload();
  };

  const selectInvoiceStatus = () => {
    const style = {
      width: "100px",
      height: "30px",
      borderRadius: "3px",
      border: "none",
      padding: "2px",
      paddingRight: "6px",
      fontWeight: "bold",
      fontSize: "16px",
    };

    const orderStatusColors = {
      RECEIVED: "rgba(214,240,255, 0.5)",
      FINALIZED: "rgba(168,239,207, 0.5)",
    };

    style.backgroundColor = orderStatusColors[props.invoice.status];

    return (
      <>
        <p style={style}>
          {props.invoice.status === "FINALIZED" ? "To pay" : "To sign"}
          {" "}
          <Icon icon={faCaretDown} color="secondary" size="S" />
        </p>
        {isStatusMenuOpen && (
          <div className={styles.dropdownMenu}>
            <div onClick={() => setOrderStatus("RECEIVED")}>To sign</div>
            <div onClick={() => setOrderStatus("FINALIZED")}>To pay</div>
            <div onClick={() => setOrderStatus("CLOSED")}>Closed</div>
          </div>
        )}
      </>
    );
  };

  const cancelStatusChange = () => {
    setOrderStatus(props.invoice.status);
    setIsKpiWarningOpen(false);
  };

  const downloadFile = async (file) => {
    const response = await adminUser.api.getFile(file.id);

    downloadFileBlob(response.data, (file.original_name || file.url));
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleStatusMenu = () => {
    setIsStatusMenuOpen(!isStatusMenuOpen);
  };

  let net_revenue = null;

  if (props.invoice.net_revenue) {
    net_revenue = props.invoice.net_revenue;
  } else if (props.invoice.leaser_rate) {
    net_revenue = ((props.invoice.rent * 100) / props.invoice.leaser_rate).toFixed(2);
  }

  const selected_company_files = props.invoice.company.files.filter(
    (f) => f.type === "SIGNATORY_ID"
      || f.type === "IBAN"
      || f.type === "INSURANCE"
      || f.type === "OTHER",
  );

  const sendPackage = async (options) => {
    await adminUser.api.sendLeaserPackage(props.invoice.id, options);
    setSendLeaserPackage(false);
  };

  // YouSign %
  const yousignStep = () => {
    const yousignSignatureRequests = props.invoice.yousign_signature_requests;

    if (yousignSignatureRequests.length) {
      const lastValidSignatureRequest = yousignSignatureRequests
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .find((p) => (p.status === "ONGOING" || p.status === "DONE") && p.signature_status !== "NONE");

      const percentages = {
        CLIENT_SIGNED_CONTRACT: 33,
        FLEET_SIGNED: 67,
        CLIENT_SIGNED_DELIVERY_RECEIPT: 67,
        COMPLETE: 100,
      };

      return (percentages[lastValidSignatureRequest?.signature_status] || 0);
    }

    const yousignProcedure = props.invoice.yousign_procedures;

    if (yousignProcedure.length) {
      const lastValidProcedure = yousignProcedure
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .find((p) => (p.status === "ACTIVE" || p.status === "COMPLETE") && p.signature_status !== "NONE");

      const percentages = {
        CLIENT_SIGNED_CONTRACT: 33,
        FLEET_SIGNED: 67,
        CLIENT_SIGNED_DELIVERY_RECEIPT: 67,
        COMPLETE: 99,
      };

      return (percentages[lastValidProcedure?.signature_status] || 0);
    }

    return 0;
  };

  // Mass Selected
  const generateCheckCircle = () => {
    const currentClassName = isHovering || props.isSelected ? styles.check : styles.hidden;

    if (props.isSelected) {
      return (
        <div className={currentClassName}>
          <Icon icon={faCheckCircle} className={styles.check} color="secondary" size="S" />
        </div>
      );
    }

    if (isHovering) {
      return (
        <div className={currentClassName}>
          <Icon icon={faCircle} className={styles.check} color="secondary" size="S" />
        </div>
      );
    }

    return <div className={currentClassName} />;
  };

  useEffect(() => {
    const status_sort = {
      RECEIVED: 1,
      FINALIZED: 2,
      CLOSED: 3,
    };

    if (typeof orderStatus === "undefined" || orderStatus === props.invoice.status) {
      return;
    }

    if (status_sort[orderStatus] < status_sort[props.invoice.status]) {
      setIsKpiWarningOpen(true);

      return;
    }

    saveStatusChange();
  }, [orderStatus]);

  const RefuseActionComponent = (
    <>
      <Button color="secondary" label="Cancel" onClick={() => setSendLeaserPackage(false)} />
      <Button
        color="primary"
        label="Send"
        onClick={() => {
          if (props.invoice.yousign_signature_requests.length) {
            sendPackage({ signature_request_id: props.invoice.yousign_signature_requests[0].signatureRequestId });
          } else if (props.invoice.yousign_procedures.length) {
            sendPackage({ procedure_id: props.invoice.yousign_procedures[0].procedureId });
          }
        }}
      />
    </>
  );

  const eligibleAFS = props.invoice.is_AFS && props.invoice.leaser === "BNP";

  return (
    <>
      {isKpiWarningOpen && (
        <tr>
          <td colSpan="9">
            <ActionConfirmation
              confirm={saveStatusChange}
              refuse={cancelStatusChange}
              message="You're about to update the invoicing status. This can impact KPIs. Are you sure you want to continue ?"
              clear={cancelStatusChange}
            />
          </td>
        </tr>
      )}
      {error && (
        <tr>
          <td colSpan="9">
            <Alert severity="error" onClose={() => setError(null)}>{error}</Alert>
          </td>
        </tr>
      )}
      <tr
        key={props.invoice.id}
        className={props.isMassEditing && (isHovering || props.isSelected) ? styles.slideRight : styles.slideLeft}
        onMouseOver={handleMouse}
        onMouseLeave={handleMouse}
      >
        <td
          className={styles.leaserCard}
          onClick={props.isMassEditing ? props.onClick : undefined}
        >
          {props.isMassEditing && generateCheckCircle()}
          {eligibleAFS ? "FR_BNP x AFS" : getLeaserLabel(props.invoice.leaser)}
        </td>
        <td onClick={() => history.push(`/orders/${props.invoice.id}`)} className={styles.requestNumber}>
          {props.invoice.request_number}
          <CopyButton value={props.invoice.request_number} />
        </td>
        <td>{props.invoice.order_number}</td>
        <td>{props.invoice.company.name}</td>
        <td>
          <FormatDate date={props.invoice.billing_date} />
        </td>
        <td className="bo-dropdown-menu-container" onClick={toggleStatusMenu}>
          {selectInvoiceStatus()}
        </td>
        <td>{props.invoice.bill_number}</td>
        <td>{net_revenue && formatMoney(net_revenue, props.invoice.rent_currency)}</td>
        {props.invoice.is_e_contract
          ? (
            <td>
              <ProgressBar value={yousignStep()} />
              {yousignStep()}
              %
            </td>
          )
          : (
            <td>
              -
            </td>
          )}
        <td>
          {" "}
          <IconButton
            icon={<Icon icon={isMenuOpen ? faCaretUp : faCaretDown} color="secondary" size="S" />}
            color={isMenuOpen ? "secondary" : "primary "}
            onClick={toggleMenu}
            size="medium"
          />
          {" "}
        </td>
        {!props.isMassEditing && (
          <td>
            <Link label="Send Leaser Package" onClick={() => setSendLeaserPackage(true)} />
          </td>
        )}

      </tr>

      <Dialog
        onClose={() => setSendLeaserPackage(false)}
        title="Send Leaser Package"
        Actions={RefuseActionComponent}
        open={sendLeaserPackage}
      >
        Are you sure you want to send the Leaser Package? An email containing all documents will be sent.
      </Dialog>

      {isMenuOpen && (
        <tr>
          <td className="invoices-files-container" colSpan="9">
            <div className="invoices-files" style={{ marginBottom: "30px" }}>
              <p className={styles.fileTitle}>Invoices</p>
              <div className="flex" style={{ gap: "50px" }}>
                <GenerateFiles
                  fileType="BILL"
                  order={props.invoice}
                  setError={setError}
                >
                  Invoice
                </GenerateFiles>
                <GenerateFiles fileType="DELIVERY_FORM" order={props.invoice}>
                  Generate Delivery form
                </GenerateFiles>
                <GenerateFiles fileType="ORDER_FORM" order={props.invoice}>
                  Generate Order form
                </GenerateFiles>
              </div>
              {Boolean(props.invoice.files.length) && (
                <>
                  <p className={styles.fileTitle}>Order files</p>
                  <div className="flex wrap spacing">
                    {props.invoice.files.map((f) => (
                      <div className="width_button">
                        <Button
                          fullWidth
                          variant="outlined"
                          color="secondary"
                          label={f.type === "OTHER" && f.description ? f.description : <FileTypeNiceName type={f.type} />}
                          onClick={() => downloadFile(f)}
                          endIcon={faDownload}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
              {Boolean(selected_company_files.length) && (
                <>
                  <p className={styles.fileTitle}>Company files</p>
                  <div className="flex wrap spacing">
                    {selected_company_files.map((f) => (
                      <div className="width_button">
                        <Button
                          fullWidth
                          variant="outlined"
                          color="secondary"
                          label={f.type === "OTHER" && f.description ? f.description : <FileTypeNiceName type={f.type} />}
                          onClick={() => downloadFile(f)}
                          endIcon={faDownload}
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default ContractSigningRow;
