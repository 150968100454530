import React, { useContext, useState } from "react";
import {
  Heading,
  Icon,
  IconButton,
  Layout,
  Link,
  Text,
  TextField,
  theme,
} from "@fleet.co/tarmac";
import { faPaperPlane } from "@fortawesome/pro-regular-svg-icons";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import "./AISupport.style.css";
import UserContext from "../../tools/UserContext";

const SvgSpinners90Ring = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" {...props}><path fill="currentColor" d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z"><animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12" /></path></svg>
);

const AISupport = () => {
  const { user } = useContext(UserContext);
  const [conversation, setConversation] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const message = formData.get("message");

    setConversation([...conversation, {
      message: formData.get("message"),
      date: new Date(),
      owner: "user",
      status: "idle",
    }, {
      message: "",
      date: new Date(),
      owner: "ai",
      status: "pending",
    }]);

    const res = await user.api.sendMessage(message);

    setConversation([...conversation, {
      message: res.data.answer,
      date: new Date(),
      owner: "ai",
      status: "idle",
      sources: res.data.sources,
    }]);

    const input = e.target.querySelector("input");

    input.value = "";
  };

  return (
    <form onSubmit={handleSubmit} className="conversation-form">
      <section className="conversation-header">
        <Heading variant="h3">
          Ask something to Fleet AI
        </Heading>
        <hr />
      </section>
      <section className="conversation-container">
        {conversation.length > 0 ? conversation.map((message) => (
          <Layout key={message.date} direction="row" spacing={2} alignItems="flex-start" justifyContent={message.owner === "user" ? "flex-end" : "flex-start"}>
            <Layout
              sx={{
                minWidth: "100px",
                maxWidth: "500px",
                backgroundColor: message.owner === "user" ? theme.palette.background.darkGreen : "#F9F9FB",
                borderRadius: 4,
                color: message.owner === "user" ? "white" : theme.palette.grey[900],
                textAlign: message.status === "pending" ? "center" : "left",
              }}
            >
              {message.status === "pending" ? (
                <Layout
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: message.owner === "user" ? theme.palette.background.darkGreen : theme.palette.grey[200],
                    padding: 1,
                    borderRadius: 4,
                  }}
                >
                  <SvgSpinners90Ring />
                  <Text variant="body2">Loading...</Text>
                </Layout>
              ) : (
                <Layout
                  direction="column"
                  spacing={1}
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  sx={{
                    backgroundColor: message.owner === "user" ? theme.palette.background.darkGreen : theme.palette.grey[200],
                    padding: 1,
                    borderRadius: 4,
                  }}
                >
                  <ReactMarkdown className="markdown-body">{message.message}</ReactMarkdown>
                </Layout>
              )}
              {message.sources?.length > 0 && (
                <Layout
                  direction="column"
                  spacing={1}
                  sx={{
                    padding: 1,
                  }}
                >
                  {message.sources.map((source, index) => (
                    <Link variant="body2" key={source.url} href={source.url} target="_blank" label={`[${index + 1}] ${source.name}`} />
                  ))}
                </Layout>
              )}
            </Layout>
          </Layout>
        )) : (
          <Layout sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
            <Text variant="body2" align="center">Start a conversation with Fleet AI</Text>
          </Layout>
        )}
      </section>
      <section className="conversation-bottom-bar">
        <section className="conversation-input">
          <TextField
            name="message"
            type="text"
            placeholder="How to block my computer remotely ?"
            inputProps={{ sx: { fontSize: 12, padding: 1 } }}
            sx={{ flex: 1 }}
          />
          <IconButton
            type="submit"
            variant="contained"
            color="darkGreen"
            icon={<Icon icon={faPaperPlane} color="secondary" size="S" />}
          />
        </section>
      </section>
    </form>
  );
};

export default AISupport;
