import React from "react";
import { Route, Switch } from "react-router-dom";

import { Layout } from "@fleet.co/tarmac";
import SideNav from "./components/sections/SideNav";
import Header from "./components/sections/Header";
import CompanyInfo from "./components/views/CompanyInfo";
import ContractSigning from "./components/views/ContractSigning";
import Leasers from "./components/views/Leasers";
import OrderInfo from "./components/views/OrderInfo";
import Logistics from "./components/views/Logistics";
import Products from "./components/views/Products";
import PunctualOffers from "./components/views/PunctualOffers";
import Unpaid from "./components/views/Unpaid";
import FileValidation from "./components/views/FileValidation";
import NoRoute from "./components/routing/NoRoute";
import FundingEnvelopes from "./components/views/FundingEnvelopes";
import Deliveries from "./components/views/Deliveries";
import Renewal from "./components/views/Renewal";
import AISupport from "./components/views/AISupport";

const AdminContainer = () => (
  <div>
    <Header />
    <Layout direction="column" sx={{ marginTop: "75px" }}>
      <SideNav />
      <Layout direction="column" sx={{ marginLeft: "64px", padding: "12px" }}>
        <Switch>
          <Route exact path="/" component={Leasers} />
          <Route exact path="/leasers" component={Leasers} />
          <Route path="/orders/:id" component={OrderInfo} />
          <Route path="/companies/:id" component={CompanyInfo} />
          <Route exact path="/contracts" component={ContractSigning} />
          <Route exact path="/logistics" component={Logistics} />
          <Route exact path="/deliveries" component={Deliveries} />
          <Route exact path="/renewal" component={Renewal} />
          <Route exact path="/products" component={Products} />
          <Route exact path="/punctual-offers" component={PunctualOffers} />
          <Route exact path="/unpaid" component={Unpaid} />
          <Route exact path="/envelopes" component={FundingEnvelopes} />
          <Route path="/files" component={FileValidation} />
          <Route exact path="/ai-it-support" component={AISupport} />
          <Route default component={NoRoute} />
        </Switch>
      </Layout>
    </Layout>
  </div>
);

export default AdminContainer;
