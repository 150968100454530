import React, { useContext, useEffect, useState } from "react";
import { Alert } from "@fleet.co/tarmac";

import UserContext from "src/tools/UserContext";

const MismatchingSkuAltert = (props) => {
  const { productId } = props;

  const [matchingProduct, setMatchingProduct] = useState(null);
  const { user: adminUser } = useContext(UserContext);

  useEffect(() => {
    const getProduct = async () => {
      const matchingProduct = await adminUser.api.getProduct(productId);

      setMatchingProduct(matchingProduct.data);
    };

    getProduct();
  }, [productId, adminUser.api, adminUser]);

  return matchingProduct && (
    <div>
      <Alert severity="error">
        This SKU matches another product:
        {" "}
        {matchingProduct.name}
        {` (ID: ${matchingProduct.id})`}
      </Alert>
    </div>
  );
};

export default MismatchingSkuAltert;
