import React from "react";
import { Layout, Text } from "@fleet.co/tarmac";
import CopyButton from "src/tools/CopyButton";

const DeviceInfoCell = ({ row: device }) => (
  <Layout direction="column">
    <Text variant="body2">{device.name}</Text>
    {["COMPUTER", "TABLET", "SMARTPHONE"].includes(device.category) && (
      <Text variant="body2">
        {`${device.storage} ${device.processor} ${device.ram}`}
      </Text>
    )}
    {device.has_abm_enrollment && (
      <Text variant="body2" color="error">
        Has ABM enrollment
      </Text>
    )}
    {device.product.primarySku && (
      <Text variant="body2" color="info" bold>
        SKU :
        {" "}
        <CopyButton value={device.product.primarySku.sku_code} />
        {device.product.primarySku.sku_code}
      </Text>
    )}
  </Layout>
);

export default DeviceInfoCell;
